<template>
  <div class="home">
    <!-- <section
      class="banner"
      :style="{
        backgroundImage: `url(${contentEnglish.company.backgroundImgUrl})`
      }"
    >
      <hgroup>
        <h1>{{ contentEnglish.company.title }}</h1>
        <h3
          v-html="$options.filters.changeLine(contentEnglish.company.subTitle)"
        ></h3>
      </hgroup>
    </section> -->
    <!-- 轮播图 -->
    <a-carousel>
      <section
        v-for="(i,index) in contentEnglish.company.banner"
        class="banner"
        :key="index"
        :style="`backgroundImage: url(${i.backgroundImgUrl})`"
      >
        <hgroup>
          <h1>{{ i.title }}</h1>
          <h3 v-html="$options.filters.changeLine(i.subTitle)" />
        </hgroup>
      </section>
    </a-carousel>
    <section class="profile">
      <common-title title="Company Profile"></common-title>
      <div class="detail">
        <p v-html="$options.filters.changeLine(contentEnglish.company.content)"></p>
      </div>
      <div class="img-box">
        <img :src="contentEnglish.company.bottomImgUrl" alt="profile image" />
      </div>
    </section>
    <section v-if="contentEnglish.produce" class="vgpu_about">
      <common-title title="Driving AI Future"></common-title>
      <p class="detail" v-html="contentEnglish.produce.produceSubject.content"></p>
      <div class="img-box">
        <img
          class="pro_img"
          :src="contentEnglish.produce.produceSubject.imgUrl"
          alt="产品介绍"
        />
      </div>
      <div class="swiper-box">
        <van-swipe
          class="my-swipe"
          indicator-color="white"
          @change="handleChange"
        >
          <van-swipe-item
            v-for="(item, index) of contentEnglish.produce.produceItems"
            :key="index"
          >
            <img class="vgpu-img" :src="item.icon" alt="Product" />
            <p>{{ item.content }}</p>
          </van-swipe-item>
          <template #indicator>
            <ul class="custom-indicator">
              <li
                v-for="(item, index) of contentEnglish.produce.produceItems"
                :key="index"
                :class="{ current: swiperAboutCurrent === index }"
              ></li>
            </ul>
          </template>
        </van-swipe>
      </div>
      <div class="purchase-link">
        Contact to purchase. <br />FREE trial is also available!
      </div>
    </section>
    <section class="customer-case row-section grey-bg">
      <common-title title="Customer Stories"></common-title>
      <section
        style="width: 100%;"
        v-if="contentEnglish.caseList && contentEnglish.caseList.length > 0"
      >
        <swiper ref="mySwiper" :options="swiperOptions" @click.native="onClickSlider">
          <swiper-slide
            v-for="(item, index) of contentEnglish.caseList"
            :key="index"
          >
            <div
              class="slide-item"
            >
              <div class="image-wrapper">
                <img :src="item.image" :alt="item.title" />
              </div>
              <div class="desc-info">
                <h4>{{ item.title }}</h4>
                <p class="info-subtitle" ref="caseDesc">{{ item.desc }}</p>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </section>
    </section>
    <!-- 新闻资讯 -->
    <section class="row-section">
      <common-title title="News & Events"></common-title>
      <section class="news">
        <ul class="news-list">
          <li
            v-for="(item, index) of contentEnglish.news"
            class="news-item"
            :class="index === 0 ? '' : 'van-hairline--top'"
            :key="item.contentItemId"
          >
            <router-link
              class="news-link"
              :to="'/en/company/news/detail?id=' + item.contentItemId"
            >
              <div class="time">
                <h3>{{ item.createdAt | getDay }}</h3>
                <p>{{ item.createdAt | getTimeExceptDay }}</p>
              </div>
              <div class="detail">
                <h3>{{ item.title }}</h3>
                <p>{{ item.decrible }}</p>
              </div>
            </router-link>
          </li>
        </ul>
      </section>
    </section>
    <!-- 合作伙伴 -->
    <section class="row-section grey-bg">
      <common-title title="Partners & Developer"></common-title>
      <section class="partner">
        <ul class="partner-list" v-if="contentEnglish.partners">
          <li
            v-for="(item, index) of contentEnglish.partners"
            :key="index"
            class="item"
          >
            <img :src="item" alt="partners logo" />
          </li>
        </ul>
      </section>
    </section>
    <section class="contact">
      <div class="info">
        <common-title title="Contact us" light="yes"></common-title>
        <ol class="contact-detail">
          <li>
            <i class="icon_1"></i>
            <div class="text_box">
              <h5>Address</h5>
              <p>{{ contentEnglish.contact.address }}</p>
              <p>12/F, Building C, Shanghai Film Plaza, No. 595, Caoxi North Road, Xuhui District, Shanghai</p>
            </div>
          </li>
          <li>
            <i class="icon_2"></i>
            <div class="text_box">
              <h5>Email</h5>
              <p>
                <a :href="'mailto:' + contentEnglish.contact.email">{{
                  contentEnglish.contact.email
                }}</a>
              </p>
            </div>
          </li>
          <li>
            <i class="icon_3"></i>
            <div class="text_box">
              <h5>Number</h5>
              <p>
                <a :href="'tel:' + contentEnglish.contact.number"></a
                >{{ contentEnglish.contact.number }}
              </p>
            </div>
          </li>
        </ol>
      </div>
      <div class="map img-box">
        <img
          :src="contentEnglish.contact.backgroundImgUrl"
          alt="map of contact"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import pageMixin from '@/mixins/pageMixin'
import commonTitle from '@/components/commonTitle'
import { mapActions, mapGetters } from 'vuex'
export default {
  mixins: [pageMixin],
  components: {
    commonTitle,
    SwiperSlide,
    Swiper
  },
  data () {
    return {
      swiperAboutCurrent: 0,
      swiperOptions: {
        slidesPerView: 'auto',
        autoplay: true,
        loop: true,
        initialSlide: 0,
        spaceBetween: 10,
        centeredSlides: true,
        preventClicks: false,
        pagination: {
          el: '.swiper-pagination',
          bulletClass: 'my-bullet',
          bulletActiveClass: 'my-bullet-active'
        }
      }
    }
  },
  computed: {
    ...mapGetters(['contentEnglish'])
  },
  filters: {
    getDay (value) {
      if (!value) return ''
      const regNumber = /\d+/g
      return value.match(regNumber)[2]
    }
  },
  methods: {
    ...mapActions(['fetchContentByKey']),
    handleChange (index) {
      this.swiperAboutCurrent = index
    },
    onClickSlider () {
      const realIndex = this.$refs.mySwiper.swiperInstance.clickedIndex % this.contentEnglish.caseList.length
      this.$router.push({
        path: '/en/case/detail',
        query: { id: this.contentEnglish.caseList[realIndex].contentItemId }
      })
    }
  },
  created () {
    this.fetchContentByKey('English')
  }
}
</script>
<style lang="less" scoped>
.home {
  .profile {
    padding: 50px 30px;
    color: @grey;
    .detail {
      margin-top: 40px;
      margin-bottom: 46px;
      text-align: center;
      font-size: 28px;
      line-height: 42px;
    }
  }
  .vgpu_about {
    .purchase-link {
      margin: 50px auto 30px;
      line-height: 46px;
      text-align: center;
      color: @blue;
      font-size: 36px;
      font-weight: 600;
    }
    .detail {
      margin-top: 26px;
      color: @grey;
      font-size: 28px;
      text-align: center;
      line-height: 44px;
      padding: 0 38px;
    }
    .img-box {
      width: 640px;
      margin: 10px auto 0;
    }
    .link {
      .radius_button_border;
      margin: 50px auto 0;
    }
    .swiper-box {
      padding: 80px 0 22px;
      .van-swipe-item {
        .flex(@direction: column);
        p {
          width: 80%;
          font-size: 30px;
          color: @grey;
          line-height: 44px;
          text-align: center;
        }
      }
      .vgpu-img {
        width: 104px;
        height: 104px;
        margin-bottom: 40px;
      }
      .custom-indicator {
        display: flex;
        justify-content: center;
        margin: 58px auto 0;
        li {
          width: 12px;
          height: 12px;
          background: #cfd4e3;
          border-radius: 6px;
          transition: 0.25s;
          &:not(:last-child) {
            margin-right: 14px;
          }
          &.current {
            width: 30px;
            background: @blue;
          }
        }
      }
    }
  }

  .news .news-list .news-item .time h3 {
    margin-bottom: 10px;
  }

  .contact {
    .info {
      .flex(@align: center, @direction: column);
      width: 100%;
      height: 560px;
      background-image: url("~@/assets/images/english/bg_contact.jpg");
      .full_bg;
      color: #fff;
      .contact-detail {
        padding: 46px 40px 0;
        li {
          .flex(flex-start);
          color: #fff;
          &:not(:last-child) {
            margin-bottom: 28px;
          }
          i {
            display: block;
            width: 36px;
            height: 36px;
            flex-shrink: 0;
            margin-right: 30px;
            .full_bg;
            .set-icon(3);
            .set-icon(@n, @i: 1) when (@i =< @n) {
              &.icon_@{i} {
                background-image: url("~@/assets/images/english/contact_@{i}.png");
              }
              .set-icon(@n, (@i+1));
            }
          }
          h5 {
            font-size: 28px;
            opacity: 0.6;
          }
          p {
            font-size: 24px;
            margin-top: 10px;
            line-height: 40px;
          }
        }
      }
    }
  }
}
</style>
